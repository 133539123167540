export let dateToDefaultValue = (date) => {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    return year + '-' + month + '-' + day;
}

export let dateToDisplayValue = (date) => {
    var day = date.getDate();
    var month = date.getMonth();
    var year = date.getFullYear();

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    if (day < 10) day = "0" + day;

    return day + " " + monthNames[month] + " " + year;
}