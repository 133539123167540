import React, { useState } from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
  Form, Input, FormGroup, Label, Alert,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

//Import graphql
import graphqlClient from '../../../graphql/index';
import { CREATE_COMPANY_BY_ADMIN } from "../../../graphql/companyQueries";

//Import Error Helper
import { getErrorMessage } from "../../../helpers/errorHelper"

//Import Env
import { DEBUG } from '../../../env'

function AccountHeader({ name, parentName, getAccounts, setSelectedAccountIndex, setSelectedMerchantIndex }) {
  const [createModal, setCreateModal] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [messageDisplay, setMessageDisplay] = useState(null);
  const [messageModalDisplay, setMessageModalDisplay] = useState(null);
  // UI Event handler
  const handleModal = () => {
    setCreateModal(!createModal);
    setMessageModalDisplay(null);
  }

  const handleSaveNewCompanyDetail = async (event) => {
    if (ownerEmail === '') {
      setMessageModalDisplay('Please enter a Owner email');
      return;
    }
    if (companyName === '') {
      setMessageModalDisplay('Please enter a Company Name');
      return;
    }
    setMessageModalDisplay(null);
    await graphqlClient
      .mutate({
        mutation: CREATE_COMPANY_BY_ADMIN,
        variables: { name: companyName, userEmail: ownerEmail, phone: companyPhone }
      })
      .then(result => {
        if (result.errors && result.errors.length > 0) {
          const errMessage = getErrorMessage(result.errors[0].message);
          setMessageModalDisplay(errMessage);
          if (DEBUG() === true) { console.log("err:" + errMessage); }
        } else {
          const merchant = result.data.CreateCompanyByAdmin.merchants[0];
          setMessageDisplay("Success created Merchant: " + merchant.title);
          if (DEBUG() === true) console.log("created merchantId:" + merchant._id.toString());
          setCreateModal(false);
          getAccounts();
          setSelectedAccountIndex(Number.MAX_SAFE_INTEGER);
          setSelectedMerchantIndex(Number.MAX_SAFE_INTEGER);
        }

      })
      .catch(err => {
        setMessageModalDisplay(err.message)
        if (DEBUG() === true) console.log("err" + err);
      });
  }

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  {name}
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                <Button className="btn-neutral" color="default" size="sm" onClick={handleModal}>
                  New
                </Button>
              </Col>
            </Row>
          </div>


          <Alert className="mb-2 mt-0" color="primary" isOpen={messageDisplay !== null} toggle={() => { setMessageDisplay(null) }}>
                  {messageDisplay}
              </Alert>
        </Container>
        <Modal isOpen={createModal} role="dialog" autoFocus={true} centered={true}>
          <ModalHeader toggle={handleModal}>New Account</ModalHeader>
          <ModalBody>
            <Form>
              <Row className="mt-4">
                <Col md="6">
                  <FormGroup className="mb-0">
                    <Label>Merchant Name :</Label>
                    <Input onChange={e => setCompanyName(e.target.value)} name="companyName" id="companyName" type="text" placeholder="Company Name" />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="position-relative">
                    <Label>Owner Email :</Label>
                    <Input onChange={e => setOwnerEmail(e.target.value)} name="ownerEmail" id="ownerEmail" type="text" placeholder="Owner Email" />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="position-relative">
                    <Label>Merchant Phone:</Label>
                    <Input onChange={e => setCompanyPhone(e.target.value)} name="companyPhone" id="companyPhone" type="text" placeholder="Company Phone" />
                  </FormGroup>
                </Col>
              </Row>

              <Alert className="mb-0 mt-3" color="primary" isOpen={messageModalDisplay !== null} toggle={() => { setMessageModalDisplay(null) }}>
                {messageModalDisplay}
              </Alert>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleModal}>Close</Button>
            <Button color="primary" onClick={handleSaveNewCompanyDetail}>Create</Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

AccountHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default AccountHeader;