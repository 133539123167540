export let InitStateData = () => {
    return {
        isLoading:false,
        isLogin: false,
        isRememberMe: false,
        userData: {
            email: null,
            firstName: null,
            lastName:null,
            role: {
              title: null
            },
            image: {
              _id: null,
              imageFullUrl: null
            },
            account: {
              _id: null,
              phone: null,
              holder: {
                email: null,
              },
              users: null,
              company: {
                _id: null,
                name: null,
                companyNumber:null,
                merchants: null
              },
              subscriptionDetail: {
                title: null,
                renewedAt: null,
                subscriptionPlan: {
                    title: null,
                    price: null,
                    renewByMonth: 0,
                    currency: {
                        symbol: '$'
                    }
                }
              }
            }
          }
    };
  }