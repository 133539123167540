import { gql } from '@apollo/client';

export const GET_ACCOUNTS = gql`
query GetAccounts($search: String!, $sort: String!, $order:String!, $page: Int!, $limit: Int!)  {
  GetAccounts(
        search: $search
        sort: $sort
        order: $order
        page: $page
        limit: $limit
      ) {
        currentPage
        totalCount
        totalPages
        accounts {
            _id
            title
            createdAt
            holder {
              email
              firstName
              lastName
            }
            company {
                _id
                name
                merchants {
                  _id
                  activated
                  verified
                  createdAt
                  title
                  phone
                  promos {
                    _id
                    title
                    activated
                    verified
                    createdAt
                    updatedAt
                    numberOfReviews
                  }
                }
            }
            subscriptionDetail{
              title
            }
        }
      }
  }
`;

export const CREATE_ACCOUNT = gql`
mutation CreateAccount($holderId: String!, $title: String, $desc: String) {
    CreateAccount(accountCreateInput:{
    holderId: $holderId
    title: $title
    desc: $desc
  }) {
    _id
    holder {
        _id
    }
    updatedAt
    createdAt
  }
}
`;

export const UPDATE_ACCOUNT_SUBSCRIPTION_DETAIL = gql`
mutation UpdateAccountSubscriptionDetailByAuth($subscriptionDetailId: String!) {
  UpdateAccountSubscriptionDetailByAuth(accountUpdateSubscriptionDetailByAuthInput:{
    subscriptionDetailId: $subscriptionDetailId
}) {
  _id
  subscriptionDetail {
      _id
  }
  updatedAt
  createdAt
}
}
`;