import React, { Component } from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

//Import LoadingOverlay
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'

//Impot State Data
import { InitStateData } from '../../helpers/stateDataHelper'

//Import Context
import AuthContext from '../../context/auth-context';

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "main/Components/Footers/PageAuthFooter.js";

import PageAdminLayout from "./PageAdminLayout"

import routes from "routes.js";

import { DEBUG } from '../../env'

class PageAdminComponent extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            ...InitStateData(),
            errorMessage: null
        }
        if (DEBUG() === true) console.log('props PageAuth' + this.props);
    }

    componentDidMount() {
        if (this.context.isLogin && this.context.userData) {
            if (DEBUG() === true) console.log('PageAdminComponent componentDidMount isLogin:' + this.context.isLogin);
            if (DEBUG() === true) console.log('PageAdminComponent componentDidMount isLogin email:' + this.context.userData.email);

            //Check login status 
            if (this.context.userData.email !== null) {
                this.setState({
                    isLoading: false,
                    isLogin: this.context.isLogin,
                    userData: this.context.userData
                });
                if (this.context.userData.account) {
                    //TOOD 
                }
            }
            
        } else {
            //This Page is for logged in user only, check login status 
            this.setState({
                isLoading: false,
                userData: null
            });
        }
    }

    render() {
        if (DEBUG() === true) console.log('render');
        if (!this.context.isLogin || this.context.userData === null) {
            if (DEBUG() === true) console.log('render isLogin:' + this.context.isLogin);
            if (DEBUG() === true) console.log('render isLogin email:' + this.context.userData.email);
            return (
                <div><Redirect from="*" to="/" /></div>
            );
        } else {
            return (
                <div>
                    <LoadingOverlay
                        active={this.state.isLoading === true}
                        spinner={<BounceLoader />} >
                        {((this.state.isLoading === false)) && (
                            <PageAdminLayout userData={this.context.userData} firebase={this.context.firebase} appLogin={this.context.appLogin} appLogout={this.context.appLogout} redirectHome={this.context.redirectHome} />
                        )}
                    </LoadingOverlay>
                </div>
    
            );
        }
        
    }
}

export default PageAdminComponent;
