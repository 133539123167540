import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// core components
import PageAdminNavbar from "../Components/Headers/PageAdminNavbar.js";
import PageAdminFooter from "../Components/Footers/PageAdminFooter.js";
import PageAdminSidebar from "../Components/Sidebars/PageAdminSidebar.js";

import SideMenus from "../Components/Sidebars/SideMenus";

import { DEBUG } from '../../env'

import PageDashboard from "./PageDashboard"
import PageManageAccount from "./PageManageAccount"

function PageAdminLayout(props) {
    const [sidenavOpen, setSidenavOpen] = React.useState(true);
    const location = useLocation();
    const mainContentRef = React.useRef(null);

    React.useEffect(() => {
        if (DEBUG() === true) console.log('PageAdminLayout' + location.pathname);
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContentRef.current.scrollTop = 0;
    }, [location]);

    const getBrandText = (path) => {
        for (let i = 0; i < SideMenus.length; i++) {
            if (location.pathname.indexOf(SideMenus[i].layout + SideMenus[i].path) !== -1) {
                return SideMenus[i].name;
            }
        }
        return "Brand";
    };
    // toggles collapse between mini sidenav and normal
    const toggleSidenav = (e) => {
        if (DEBUG() === true) console.log('toggleSidenav');

        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }
        setSidenavOpen(!sidenavOpen);
    };
    const getNavbarTheme = () => {
        return location.pathname.indexOf("admin/alternative-dashboard") === -1
            ? "dark"
            : "light";
    };

    return (
        <>
            <PageAdminSidebar
                routes={SideMenus}
                toggleSidenav={toggleSidenav}
                sidenavOpen={sidenavOpen}
                logo={{
                    innerLink: "/",
                    imgSrc: require("assets/img/brand/logo.png").default,
                    imgAlt: "...",
                }}
            />
            <div className="main-content" ref={mainContentRef}>
                <PageAdminNavbar
                    theme={getNavbarTheme()}
                    toggleSidenav={toggleSidenav}
                    sidenavOpen={sidenavOpen}
                    brandText={getBrandText(location.pathname)}
                    userData={props.userData}
                    firebase={props.firebase}
                    appLogin={props.appLogin}
                    appLogout={props.appLogout}
                />

                {
                    (location.pathname === "/admin/dashboard" || location.pathname === "/admin") ?
                        <PageDashboard
                            userData={props.userData}
                            firebase={props.firebase}
                            appLogin={props.appLogin}
                            appLogout={props.appLogout}
                        />
                        :
                        (location.pathname === "/admin/account") ?
                            <PageManageAccount 
                                userData={props.userData}
                                firebase={props.firebase}
                                appLogin={props.appLogin}
                                appLogout={props.appLogout}
                                redirectHome={props.redirectHome}
                            />
                        :
                        <PageDashboard
                            userData={props.userData}
                            firebase={props.firebase}
                            appLogin={props.appLogin}
                            appLogout={props.appLogout}
                            redirectHome={props.redirectHome}
                        />
                }


                <PageAdminFooter />
            </div>
            {sidenavOpen ? (
                <div className="backdrop d-xl-none" onClick={toggleSidenav} />
            ) : null}
        </>
    );
}

export default PageAdminLayout;