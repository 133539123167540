//Import Env
import { DEBUG } from '../env'

export let getErrorMessage = (errMessage) => {
    try {
        if (DEBUG() === true) console.log('errMessage:' + errMessage);
        const errMessageJson = JSON.parse(errMessage);
        if (errMessageJson.subError) {
            return errMessageJson.subError.message;
        }
    } catch (err) {
        if (DEBUG() === true) console.log('json error:' + err.message);
    }

    return errMessage;
};
