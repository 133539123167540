import { gql } from '@apollo/client';

export const GET_PROMOTION_BY_ID = gql`
query GetPromoById($promotionId: String!)  {
    GetPromoById(
        promoId: $promotionId
      ) {
            _id
            deleted
            verified
            activated
            createdAt
            updatedAt
            title
            category
            desc
            shortDesc
            industry
            website
            conditions
            numberOfReviews
            latitude
            longitude
            fullAddress
            weekly
            fortnightly
            monthly
            yearly
            fromDate
            toDate
            price
            priceType
            discount
            tags {
                _id
            }
            merchant {
                _id
                createdAt
                updatedAt
                title
                desc
                industry
                website
                deleted
                verified
                activated
                website
                phone
                facebook
                instagram
                linkedIn
                googlePlace
                googlePlaceId
                latitude
                longitude
                fullAddress
                openingHoursJson
                logoImage {
                    _id
                    updatedAt
                    imageFullUrl
                    imageThumbUrl
                }
                backgroundImage {
                    _id
                    updatedAt
                    imageFullUrl
                    imageThumbUrl
                }
                staffUsers {
                    _id
                }
            }
            numberOfReviews
            totalReviewRating
            reviews {
                _id
            }
            logoImage {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            image1 {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            image2 {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            image3 {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            image4 {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            image5 {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
        }
    }
`;

export const GET_PROMOTIONS = gql`
query GetPromos($search: String!, $lat: Float!, $lng: Float!, $page: Int!, $limit: Int!)  {
    GetPromos(
        search: $search
        lat: $lat
        lng: $lng
        maxDistance:10000
        page: $page
        limit: $limit
      ) {
        currentPage
        totalPages
        promos {
            _id
            deleted
            verified
            activated
            createdAt
            updatedAt
            title
            shortDesc
            category
            industry
            website
            conditions
            numberOfReviews
            latitude
            longitude
            fullAddress
            weekly
            fortnightly
            monthly
            yearly
            fromDate
            toDate
            price
            priceType
            discount
            tags {
                _id
            }
            merchant {
                _id
                createdAt
                updatedAt
                title
                desc
                industry
                website
                deleted
                verified
                activated
                website
                phone
                facebook
                instagram
                linkedIn
                googlePlace
                googlePlaceId
                latitude
                longitude
                fullAddress
                openingHoursJson
                logoImage {
                    _id
                    updatedAt
                    imageFullUrl
                    imageThumbUrl
                }
                backgroundImage {
                    _id
                    updatedAt
                    imageFullUrl
                    imageThumbUrl
                }
            }
            numberOfReviews
            totalReviewRating
            reviews {
                _id
            }
            logoImage {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            image1 {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            image2 {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            image3 {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            image4 {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            image5 {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
        }
    }
  }
`;

export const CREATE_PRMOTION_BY_MERCHANT = gql`
mutation CreatePromoByMerchant($merchantId: String!, 
                        $title: String!
                        ) {
                            CreatePromoByMerchant(promoGreateByMerchantInput:{
                                merchantId: $merchantId
                                title: $title
  }) {
        _id
        createdAt
        updatedAt
        title
        deleted
        verified
        activated
  }
}
`;

export const UPDATE_PRMOTION = gql`
mutation UpdatePromo($promoId: String!, 
                        $title: String, 
                        $category: String,
                        $desc: String,
                        $shortDesc: String, 
                        $conditions: String
                        $industry: String,
                        $website: String,
                        $verified: Boolean,
                        $activated: Boolean,
                        $weekly: Boolean,
                        $fortnightly: Boolean,
                        $monthly: Boolean,
                        $yearly: Boolean,
                        $logoImageId: String, 
                        $image1Id: String, 
                        $image2Id: String, 
                        $image3Id: String, 
                        $image4Id: String, 
                        $image5Id: String,
                        $fullAddress: String, 
                        $latitude: String,
                        $longitude: String,
                        $fromDate: String,
                        $toDate: String
                        $price: String,
                        $priceType: String,
                        $discount: String
                        ) {
    UpdatePromo(promoUpdateInput:{
        promoId: $promoId
        title: $title
        category: $category
        desc: $desc
        shortDesc: $shortDesc
        conditions: $conditions
        industry: $industry
        website: $website
        verified: $verified
        activated: $activated
        weekly: $weekly
        fortnightly: $fortnightly
        monthly: $monthly
        yearly: $yearly
        logoImageId: $logoImageId
        image1Id: $image1Id
        image2Id: $image2Id
        image3Id: $image3Id
        image4Id: $image4Id
        image5Id: $image5Id
        fullAddress: $fullAddress
        latitude: $latitude
        longitude: $longitude
        fromDate: $fromDate
        toDate: $toDate
        price: $price
        priceType: $priceType
        discount: $discount
  }) {
        _id
        deleted
        verified
        activated
        createdAt
        updatedAt
        title
        category
        desc
        shortDesc
        industry
        website
        conditions
        numberOfReviews
        latitude
        longitude
        fullAddress
        weekly
        fortnightly
        monthly
        yearly
        fromDate
        toDate
        price
        priceType
        discount
        tags {
            _id
        }
        merchant {
            _id
            createdAt
            updatedAt
            title
            desc
            industry
            website
            deleted
            verified
            activated
            website
            phone
            facebook
            instagram
            linkedIn
            googlePlace
            googlePlaceId
            latitude
            longitude
            fullAddress
            openingHoursJson
            logoImage {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            backgroundImage {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            staffUsers {
                _id
            }
        }
        numberOfReviews
        totalReviewRating
        reviews {
            _id
        }
        logoImage {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
        }
        image1 {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
        }
        image2 {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
        }
        image3 {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
        }
        image4 {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
        }
        image5 {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
        }
    }
}
`;