import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Route, Switch, useHistory } from "react-router-dom";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Alert,
} from "reactstrap";
// core components
import PageAuthHeader from "./PageAuthHeader.js";
//Import Firebase
import { setRememberUser } from '../../helpers/sessionHelper';

import { DEBUG } from '../../env'

function PageLogin(props) {

    const [isLoading, setLoading] = useState(false);
    const [firebaseWeb] = useState(props.firebase);
    const [userData, setUserData] = useState(props.userData);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [focusedEmail, setfocusedEmail] = React.useState(false);
    const [focusedPassword, setfocusedPassword] = React.useState(false);
    const [isRememberMe, setRememberMe] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const history = useHistory();

    // UI Event handler
    const handleLogin = async () => {
        if (DEBUG() === true) console.log('PageLogin isRememberMe:' + isRememberMe.toString());
        setLoading(true);
        if (DEBUG() === true) console.log('PageLogin email:' + email);
        setErrorMessage(null);
        var loginErrorMessage = null;
        const appLogin = await props.appLogin(email, password);
        loginErrorMessage = appLogin.errMessage;
        if (loginErrorMessage !== null) {
            setErrorMessage(loginErrorMessage);
            setLoading(false);
        } else {
            setRememberUser(isRememberMe);
            history.push('/admin');
        }
    }

    const handleRememberMeClick = () => {
        setRememberMe(!isRememberMe);
    }

    React.useEffect(() => {
        if (userData != null) {
            if (DEBUG() === true) console.log('PageLogin email:' + userData.email);
        }
    }, []);

    return (
        <div>
            <LoadingOverlay
                active={isLoading === true} spinner={<BounceLoader />} >
                <PageAuthHeader
                    title="Pin a Promo Dashboard"
                    lead="Manager your merchant and more..."
                />

                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <Form role="form">
                                        <FormGroup
                                            className={classnames("mb-3", {
                                                focused: focusedEmail,
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email"
                                                    type="email"
                                                    onFocus={() => setfocusedEmail(true)}
                                                    onBlur={() => setfocusedEmail(true)}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup
                                            className={classnames({
                                                focused: focusedPassword,
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Password"
                                                    type="password"
                                                    onFocus={() => setfocusedPassword(true)}
                                                    onBlur={() => setfocusedPassword(true)}
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <div className="custom-control custom-control-alternative custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                id=" customCheckLogin"
                                                type="checkbox"
                                                checked={isRememberMe}
                                                onChange={handleRememberMeClick}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor=" customCheckLogin"
                                            >
                                                <span className="text-muted">Remember me</span>
                                            </label>
                                        </div>
                                        <div className="text-center">
                                            <Button onClick={handleLogin} className="my-4" color="info" type="button">
                                                Sign in
                                            </Button>
                                        </div>
                                    </Form>
                                    <Alert className="mb-0 mt-3" color="info" isOpen={errorMessage != null} toggle={() => setErrorMessage(null)}>
                                        Error: {errorMessage}.
                                    </Alert>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">
                                <Col xs="6">
                                    <a
                                        className="text-light"
                                        href="https://pinapromo.co.nz/auth-re-password"
                                        target="_blank" rel="noreferrer"
                                    >
                                        <small>Forgot password?</small>
                                    </a>
                                </Col>
                                <Col className="text-right" xs="6">
                                    <a
                                        className="text-light"
                                        href="https://pinapromo.co.nz/auth-signup"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <small>Create new account</small>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </LoadingOverlay>
        </div>
    );
}

export default PageLogin;