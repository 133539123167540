import React, { useState } from 'react';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    Table,
    Row,
    Col,
    Badge,
    UncontrolledTooltip,
    Form, Input, FormGroup, Label, Alert,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

//Import graphql
import graphqlClient from '../../../graphql/index';
import { CREATE_MERCHANT } from "../../../graphql/merchantQueries";

//Import Error Helper
import { getErrorMessage } from "../../../helpers/errorHelper"

//Import Env
import { DEBUG } from '../../../env'

import { dateToDisplayValue } from '../../../helpers/dateHelper.js'

function MerchantCard(props) {

    const [createModal, setCreateModal] = useState(false);
    const [merchantName, setMerchantName] = useState('');
    const [messageModalDisplay, setMessageModalDisplay] = useState(null);
    // UI Event handler
    const handleModal = () => {
        setCreateModal(!createModal);
        setMessageModalDisplay(null);
    }

    const handleSaveNewMerchantDetail = async (event) => {
        if (merchantName === '') {
            setMessageModalDisplay('Please enter a Merchant Name');
            return;
        }
        setMessageModalDisplay(null);
        await graphqlClient
            .mutate({
                mutation: CREATE_MERCHANT,
                variables: { companyId: props.companyId, title: merchantName }
            })
            .then(result => {
                if (result.errors && result.errors.length > 0) {
                    const errMessage = getErrorMessage(result.errors[0].message);
                    setMessageModalDisplay(errMessage);
                    if (DEBUG() === true) { console.log("err:" + errMessage); }
                } else {
                    setCreateModal(false);
                    props.getAccounts();
                    props.setSelectedMerchantIndex(Number.MAX_SAFE_INTEGER);
                }
            })
            .catch(err => {
                setMessageModalDisplay(err.message)
                if (DEBUG() === true) console.log("err" + err);
            });
    }

    const handleGetDisplayDate = (datestr) => {
        if (datestr) {
            const date = new Date(datestr);
            return dateToDisplayValue(date);
        }
        return '';
    }

    const handleGetMerchantUrl = (merchant) => {
        var url = "https://pinapromo.co.nz/merchant-detail?" + merchant.title + "&" + merchant._id;
        return url;
    }

    const handleGetPromoUrl = (promo) => {
        var url = "https://pinapromo.co.nz/promotion-detail?" + promo.title + "&" + promo._id;
        return url;
    }

    const handleSelectMerchant = (event, index) => {
        props.setSelectedMerchantIndex(index);
    };

    const handleGetClassNameSelectedMerchantIndex = (index) => {
        if (props.selectedMerchantIndex === index) {
            return "table-success";
        }
        return "table";
    }

    const handleGetClassNameStatus = (isTrue) => {
        if (isTrue) {
            return "bg-success"
        }

        return "bg-warning";
    }

    return <div>
        {props.selectedAccountMerchants !== null && (
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0">Merchant</h3>
                        </Col>
                        <Col className="text-right" xs="6">
                            <Button
                                className="btn-neutral btn-round btn-icon"
                                color="default"
                                onClick={handleModal}
                                size="sm"
                            >
                                <span className="btn-inner--text">New</span>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th>Name</th>
                            <th>Created at</th>
                            <th>Phone</th>
                            <th>Status</th>
                            <th>Promotions</th>
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {props.selectedAccountMerchants !== null && (
                            props.selectedAccountMerchants.map((mechant, key) =>
                                <tr key={key} className={handleGetClassNameSelectedMerchantIndex(key)} >
                                    <td className="table-user" onClick={e => handleSelectMerchant(e, key)}>
                                        <b>{mechant.title}</b>
                                    </td>
                                    <td>
                                        <span className="text-muted">
                                            {handleGetDisplayDate(mechant.createdAt)}
                                        </span>
                                    </td>
                                    <td>
                                        {mechant.phone}
                                    </td>
                                    <td>
                                        <Badge color="" className="badge-dot mr-3">
                                            <i className={handleGetClassNameStatus(mechant.verified)} />
                                            <span className="status">verify</span>
                                        </Badge>
                                        <Badge color="" className="badge-dot">
                                            <i className={handleGetClassNameStatus(mechant.activated)} />
                                            <span className="status">activate</span>
                                        </Badge>
                                    </td>
                                    <td>
                                        <div className="avatar-group">
                                            {mechant.promos.map((promo, key) =>
                                                <div className="avatar avatar-sm rounded-circle" key={key}>
                                                    <a
                                                        href={handleGetPromoUrl(promo)}
                                                        target="_blank" rel="noreferrer"
                                                        id="tooltip601065235888"
                                                    >
                                                        {promo.title !== null && (
                                                            <div className="centered">{promo.title.substring(0, 1)}</div>
                                                        )}
                                                    </a>

                                                    <UncontrolledTooltip key={key} delay={0} target="tooltip601065235888">
                                                        Edit Promotion
                                                    </UncontrolledTooltip>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td className="table-actions">
                                        <a
                                            className="table-action table-action-delete"
                                            href={handleGetMerchantUrl(mechant)}
                                            target="_blank" rel="noreferrer"
                                            id="tooltip601065235889"
                                        >
                                            <i className="fas fa-edit" />
                                        </a>
                                        <UncontrolledTooltip delay={0} target="tooltip601065235889">
                                            Edit Merchant
                                        </UncontrolledTooltip>
                                    </td>
                                    <td className="table-actions">
                                        <a
                                            className="table-action"
                                            href="#pablo"
                                            id="tooltip564981685"
                                            onClick={e => handleSelectMerchant(e, key)}
                                        >
                                            <i className="fas fa-pager" />
                                        </a>
                                        <UncontrolledTooltip delay={0} target="tooltip564981685">
                                            Manager Promotion
                                        </UncontrolledTooltip>
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
                <Modal isOpen={createModal} role="dialog" autoFocus={true} centered={true}>
                    <ModalHeader toggle={handleModal}>New Merchant</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className="mt-4">
                                <Col md="6">
                                    <FormGroup className="mb-0">
                                        <Label>Merchant Name :</Label>
                                        <Input onChange={e => setMerchantName(e.target.value)} name="merchantName" id="merchantName" type="text" placeholder="Merchant Name" />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Alert className="mb-0 mt-3" color="primary" isOpen={messageModalDisplay !== null} toggle={() => { setMessageModalDisplay(null) }}>
                                {messageModalDisplay}
                            </Alert>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleModal}>Close</Button>
                        <Button color="primary" onClick={handleSaveNewMerchantDetail}>Create</Button>
                    </ModalFooter>
                </Modal>
            </Card>
        )}
    </div>
}

export default MerchantCard;