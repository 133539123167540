import React, { useState } from 'react';

// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    Media,
    Table,
    UncontrolledTooltip,
} from "reactstrap";

import { dateToDisplayValue } from '../../../helpers/dateHelper.js'

import ListPagination from "./ListPagination";

function AccountCard(props) {

    const handleGetDisplayDate = (datestr) => {
        if (datestr) {
            const date = new Date(datestr);
            return dateToDisplayValue(date);
        }
        return '';
    }

    const handleGetMerchantUrl = (merchant) => {
        var url = "https://pinapromo.co.nz/merchant-detail?" + merchant.title + "&" + merchant._id;
        return url;
    }

    const handleGetSortName = () => {
        if (props.sort === "title") {
            if (props.order === "desc") {
                return <th className="sort"
                    data-sort-desc="name"
                    onClick={e => { props.handleChangeSort(e, "title", "asc"); }}
                >
                    Name
                </th>
            } else {
                return <th className="sort"
                    data-sort-asc="name"
                    onClick={e => { props.handleChangeSort(e, "title", "desc"); }}
                >
                    Name
                </th>
            }
        }
        return <th className="sort" data-sort-disable="name"
            onClick={e => { props.handleChangeSort(e, "title", "asc"); }}
        >
            Name
        </th>
    }

    const handleGetSortCreatedAt = () => {
        if (props.sort === "createdAt") {
            if (props.order === "desc") {
                return <th className="sort"
                    data-sort-desc="createdAt"
                    onClick={e => { props.handleChangeSort(e, "createdAt", "asc"); }}
                >
                    Created At
                </th>
            } else {
                return <th className="sort"
                    data-sort-asc="createdAt"
                    onClick={e => { props.handleChangeSort(e, "createdAt", "desc"); }}
                >
                    Created At
                </th>
            }
        }
        return <th className="sort" data-sort-disable="createdAt"
            onClick={e => { props.handleChangeSort(e, "createdAt", "desc"); }}
        >
            Created At
        </th>
    }

    const handleGetClassNameSelectedAccountIndex = (index) => {
        if (props.selectedAccountIndex === index) {
            return "table-success";
        }
        return "table";
    }

    const handleSelectAccount = (event, index) => {
        props.setSelectedAccountIndex(index);
    };

    return <div>
        <Card>
            <CardHeader className="border-0">
                {props.accountData !== null && props.accountData.accounts !== null ?
                    <h3 className="mb-0">
                        Account: ({props.accountData.totalCount})
                    </h3>
                    :
                    <h3 className="mb-0">
                        Accounts
                    </h3>
                }
            </CardHeader>

            {props.accountData !== null && (
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                            {handleGetSortName()}
                            {handleGetSortCreatedAt()}
                            <th className="holder">
                                Owner Email
                            </th>
                            <th scope="col">Merchants</th>
                            <th className="holder">
                                Subscription
                            </th>
                            <th scope="col" />
                        </tr>
                    </thead>
                    <tbody className="list">
                        {props.accountData.accounts !== null && (
                            props.accountData.accounts.map((account, key) =>
                                <tr className={handleGetClassNameSelectedAccountIndex(key)} key={key}>
                                    <th scope="row" onClick={e => handleSelectAccount(e, key)}>
                                        <Media className="align-items-center">
                                            <Media>
                                                <span className="name mb-0 text-sm">
                                                    {account.title}
                                                </span>
                                            </Media>
                                        </Media>
                                    </th>
                                    <td className="budget">{handleGetDisplayDate(account.createdAt)}</td>
                                    <td>
                                        <Badge color="" className="badge-dot mr-4">
                                            <span className="status">{account.holder.email}</span>
                                        </Badge>
                                    </td>
                                    <td>
                                        <div className="avatar-group">
                                            {account.company.merchants.map((merchant, key) =>
                                                <div className="avatar avatar-sm rounded-circle" key={key}>
                                                    <a
                                                        href={handleGetMerchantUrl(merchant)}
                                                        id="tooltip601065235999"
                                                        target="_blank" rel="noreferrer"
                                                    >
                                                        <div className="centered">{merchant.title.substring(0, 1)}</div>
                                                    </a>
                                                    <UncontrolledTooltip delay={0} target="tooltip601065235999">
                                                        Edit Merchant
                                                    </UncontrolledTooltip>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                {account.subscriptionDetail.title}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <a
                                            className="table-action"
                                            href="#pablo"
                                            id="tooltip001"
                                            onClick={e => handleSelectAccount(e, key)}
                                        >
                                            <i className="fas fa-store" />
                                        </a>
                                        <UncontrolledTooltip delay={0} target="tooltip001">
                                            Manage Merchant
                                        </UncontrolledTooltip>
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
            )}

            <CardFooter className="py-4">
                <nav aria-label="...">
                    <ListPagination
                        totalPage={props.totalPages}
                        currentPage={props.currentPage}
                        handleChangePage={props.handleChangePage}
                    />
                </nav>
            </CardFooter>
        </Card>
    </div>
}

export default AccountCard;