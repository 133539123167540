import React, { Component, Suspense } from "react";
// react library for routing
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";

//Impot State Data
import { InitStateData } from './helpers/stateDataHelper'
//Import Firebase
import { firebaseWeb } from './firebase/index'

//Import Context
import AuthContext from './context/auth-context';
import { updateAuth, logoutAuth } from './context/AuthManager';

//Import GraphQL
import { ApolloProvider } from '@apollo/client';
import graphqlClient from '../src/graphql/index'

import routes from "../src/routes";

//Import Env
import { DEBUG } from './env'

import PageAdminComponent from "./main/Admin/PageAdminComponent.js";
import PageAuthComponent from "./main/Auth/PageAuthComponent.js";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...InitStateData()
        };
    }

    appLogin = async (email, password) => {
        var errMessage = null;
        const userCredential = await firebaseWeb().auth().signInWithEmailAndPassword(email, password).catch(function (error) {
            var errorCode = error.code;
            if (DEBUG() === true) console.log('errorCode' + errorCode);
            errMessage = error.message;
            if (DEBUG() === true) console.log('errMessage:' + errMessage);
        });

        if (userCredential != null) {
            if (DEBUG() === true) console.log('await updateAuth');
            await updateAuth(this, firebaseWeb());
        }

        return {
            userCredential: userCredential,
            errMessage: errMessage,
        };
    }

    appLogout = async () => {
        await logoutAuth(this, firebaseWeb());
        this.redirectHome();
    };

    redirectHome = () => {
        window.location.href = "/";
    }

    componentDidMount() {
        if (DEBUG() === true) console.log('App componentDidMount');
        updateAuth(this, firebaseWeb());
    }

    componentWillUnmount() {
        if (DEBUG() === true) console.log('App componentWillUnmount');
    }

    Loader = () => {
        return (
          <div id="preloader">
            <div id="status">
            </div>
          </div>
        );
      }

    render() {

        if (DEBUG() === true) console.log('App render user role title:' + this.state.userData.role.title);
        if (DEBUG() === true) console.log('App render user email:' + this.state.userData.email);
        if (DEBUG() === true) console.log('App render user id:' + this.state.userData._id);
        if (DEBUG() === true) console.log('App render user islogin:' + this.state.isLogin);

        return (
            <ApolloProvider client={graphqlClient}>
                <React.Fragment>
                    <AuthContext.Provider
                        value={{
                            isLogin: this.state.isLogin,
                            userData: this.state.userData,
                            appLogin: this.appLogin,
                            appLogout: this.appLogout,
                            redirectHome: this.redirectHome,
                            firebase: firebaseWeb()
                        }}
                    >
                        <Router>
                            <Suspense fallback={this.Loader()} >
                                <Switch>
                                    <Route path="/admin" render={(props) => <PageAdminComponent {...props} />} />
                                    <Route path="/" render={(props) => <PageAuthComponent {...props} />} />
                                </Switch>
                            </Suspense>
                        </Router>
                    </AuthContext.Provider>
                </React.Fragment>
            </ApolloProvider>
        );
    }
}

export default App;