import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/storage';
import firebaseConfig from '../firebase-config.json'
import { setFirebaseUser, removeFirebaseUser } from '../helpers/sessionHelper';
//Import Env
import { DEBUG } from '../env'

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
        const userJson = JSON.stringify(user);
        const data = JSON.parse(userJson);
        if (DEBUG() === true) console.log('This is the user: ', userJson);
        const stsTokenManager = data.stsTokenManager;
        const now = new Date();
		const expired = new Date(stsTokenManager.expirationTime);
		if (now < expired) {
            setFirebaseUser(user);
        } else {
            removeFirebaseUser();
        }
    } else {
        // No user is signed in.
        if (DEBUG() === true) console.log('There is no logged in user');
    }
});

export const firebaseWeb = () => {
    return firebase;
}