import React, { useState } from 'react';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    Table,
    Row,
    Col,
    Badge,
    UncontrolledTooltip,
    Form, Input, FormGroup, Label, Alert,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

//Import graphql
import graphqlClient from '../../../graphql/index';
import { CREATE_PRMOTION_BY_MERCHANT } from "../../../graphql/promotionQueries";

//Import Error Helper
import { getErrorMessage } from "../../../helpers/errorHelper"

//Import Env
import { DEBUG } from '../../../env'

import { dateToDisplayValue } from '../../../helpers/dateHelper.js'

function PromotionCard(props) {

    const [createModal, setCreateModal] = useState(false);
    const [promotionName, setPromotionName] = useState('');
    const [messageModalDisplay, setMessageModalDisplay] = useState(null);
    // UI Event handler
    const handleModal = () => {
        setCreateModal(!createModal);
        setMessageModalDisplay(null);
    }

    const handleSaveNewMerchantDetail = async (event) => {
        if (promotionName === '') {
            setMessageModalDisplay('Please enter a Promotion name');
            return;
        }
        setMessageModalDisplay(null);
        await graphqlClient
            .mutate({
                mutation: CREATE_PRMOTION_BY_MERCHANT,
                variables: { merchantId: props.merchantId, title: promotionName }
            })
            .then(result => {
                if (result.errors && result.errors.length > 0) {
                    const errMessage = getErrorMessage(result.errors[0].message);
                    setMessageModalDisplay(errMessage);
                    if (DEBUG() === true) { console.log("err:" + errMessage); }
                } else {
                    setCreateModal(false);
                    props.getAccounts();
                }
            })
            .catch(err => {
                setMessageModalDisplay(err.message)
                if (DEBUG() === true) console.log("err" + err);
            });
    }

    const handleGetDisplayDate = (datestr) => {
        if (datestr) {
            const date = new Date(datestr);
            return dateToDisplayValue(date);
        }
        return '';
    }

    const handleSelectPromo = (event, index) => {
        props.setSelectedPromoIndex(index);
    };

    const handleGetPromoUrl = (promo) => {
        var url = "https://pinapromo.co.nz/promotion-detail?" + promo.title + "&" + promo._id;
        return url;
    }

    const handleGetClassNameStatus = (isTrue) => {
        if (isTrue) {
            return "bg-success"
        }

        return "bg-warning";
    }

    return <div>
        {props.selectedAccountMerchantPromos !== null && (
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0">Promotion</h3>
                        </Col>
                        <Col className="text-right" xs="6">
                            <Button
                                className="btn-neutral btn-round btn-icon"
                                color="default"
                                id="tooltip969372949"
                                onClick={handleModal}
                                size="sm"
                            >
                                <span className="btn-inner--text">New</span>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th>Name</th>
                            <th>Created at</th>
                            <th>Updated At</th>
                            <th>Status</th>
                            <th>Reviews</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {props.selectedAccountMerchantPromos !== null && (
                            props.selectedAccountMerchantPromos.map((promo, key) =>
                                <tr key={key}>
                                    <td className="table-user">
                                        <b>{promo.title}</b>
                                    </td>
                                    <td>
                                        <span className="text-muted">
                                            {handleGetDisplayDate(promo.createdAt)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text-muted">
                                            {handleGetDisplayDate(promo.updatedAt)}
                                        </span>
                                    </td>
                                    <td>
                                        <Badge color="" className="badge-dot mr-3">
                                            <i className={handleGetClassNameStatus(promo.verified)} />
                                            <span className="status">verify</span>
                                        </Badge>
                                        <Badge color="" className="badge-dot">
                                            <i className={handleGetClassNameStatus(promo.activated)} />
                                            <span className="status">activate</span>
                                        </Badge>
                                    </td>
                                    <td>
                                        <div>
                                            <span className="status">{promo.numberOfReviews}</span>
                                        </div>
                                    </td>
                                    <td className="table-actions">
                                        <a
                                            className="table-action table-action-delete"
                                            href={handleGetPromoUrl(promo)}
                                            target="_blank" rel="noreferrer"
                                            id="tooltip601065235889"
                                        >
                                            <i className="fas fa-edit" />
                                        </a>
                                        <UncontrolledTooltip delay={0} target="tooltip601065235889">
                                            Edit Promotion
                                        </UncontrolledTooltip>
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
                <Modal isOpen={createModal} role="dialog" autoFocus={true} centered={true}>
                    <ModalHeader toggle={handleModal}>New Promotion</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className="mt-4">
                                <Col md="6">
                                    <FormGroup className="mb-0">
                                        <Label>Promotion Name :</Label>
                                        <Input onChange={e => setPromotionName(e.target.value)} name="promotionName" id="promotionName" type="text" placeholder="Promotion Name" />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Alert className="mb-0 mt-3" color="primary" isOpen={messageModalDisplay !== null} toggle={() => { setMessageModalDisplay(null) }}>
                                {messageModalDisplay}
                            </Alert>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleModal}>Close</Button>
                        <Button color="primary" onClick={handleSaveNewMerchantDetail}>Create</Button>
                    </ModalFooter>
                </Modal>
            </Card>
        )}
    </div>
}

export default PromotionCard;