import { gql } from '@apollo/client';

export const GET_MERCHANT_BY_ID = gql`
query GetMerchantById($merchantId: String!)  {
    GetMerchantById(
        merchantId: $merchantId
      ) {
        _id
        createdAt
        updatedAt
        title
        desc
        industry
        website
        deleted
        verified
        activated
        website
        phone
        facebook
        instagram
        linkedIn
        googlePlace
        googlePlaceId
        latitude
        longitude
        fullAddress
        openingHoursJson
        primaryCategory {
            _id
        }
        secondaryCategory {
            _id
        }
        tertiaryCategory {
            _id
        }
        location {
            _id
        }
        logoImage {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
        }
        backgroundImage {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
        }
        promos {
            _id
            updatedAt
            title
            category
            shortDesc
            industry
            conditions
            fromDate
            toDate
            price
            priceType
            discount
            fullAddress
            numberOfReviews
            totalReviewRating
            logoImage {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            merchant {
                _id
                createdAt
                updatedAt
                title
            }
        }
        numberOfReviews
        totalReviewRating
        reviews {
            _id
        }
        staffUsers {
            _id
        }
    }
  }
`;

export const GET_MERCHANTS = gql`
query GetMerchants($search: String!, $lat: Float!, $lng: Float!, $page: Int!, $limit: Int!)  {
    GetMerchants(
        search: $search
        lat: $lat
        lng: $lng
        maxDistance:10000
        page: $page
        limit: $limit
      ) {
        currentPage
        totalPages
        merchants {
            _id
            createdAt
            updatedAt
            title
            desc
            industry
            website
            deleted
            verified
            activated
            website
            phone
            facebook
            instagram
            linkedIn
            googlePlace
            googlePlaceId
            latitude
            longitude
            fullAddress
            openingHoursJson
            primaryCategory {
                _id
            }
            secondaryCategory {
                _id
            }
            tertiaryCategory {
                _id
            }
            location {
                _id
            }
            logoImage {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            backgroundImage {
                _id
                updatedAt
                imageFullUrl
                imageThumbUrl
            }
            promos {
                _id
                updatedAt
                title
                category
                shortDesc
                desc
                conditions
                numberOfReviews
                totalReviewRating
                fullAddress
                fromDate
                toDate
                price
                priceType
                discount
            }
            numberOfReviews
            totalReviewRating
            reviews {
                updatedAt
                _id
            }
        }
    }
  }
`;

export const CREATE_MERCHANT = gql`
mutation CreateMerchant($companyId: String!, 
                        $title: String!
                        ) {
    CreateMerchant(merchantCreateInput:{
    companyId: $companyId
    title: $title
  }) {
        _id
        createdAt
        updatedAt
        title
        deleted
        verified
        activated
  }
}
`;

export const UPDATE_MERCHANT = gql`
mutation UpdateMerchant($merchantId: String!, 
                        $title: String, 
                        $desc: String, 
                        $industry: String,
                        $logoImageId: String,
                        $backgroundImageId: String,
                        $website: String, 
                        $phone: String, 
                        $facebook: String, 
                        $instagram: String, 
                        $linkedIn: String, 
                        $googlePlace: String,
                        $fullAddress: String, 
                        $openingHoursJson: String,
                        $latitude: String,
                        $longitude: String,
                        $locationId: String,
                        $verified: Boolean,
                        $activated: Boolean,
                        $imageIds: [String!],
                        $promoIds: [String!],
                        $reviewIds: [String!]
                        ) {
    UpdateMerchant(merchantUpdateInput:{
        merchantId: $merchantId
        title: $title
        desc: $desc
        industry: $industry
        logoImageId: $logoImageId
        backgroundImageId: $backgroundImageId
        website: $website
        phone: $phone
        facebook: $facebook
        instagram: $instagram
        linkedIn: $linkedIn
        googlePlace: $googlePlace
        fullAddress: $fullAddress
        openingHoursJson: $openingHoursJson
        latitude: $latitude
        longitude: $longitude
        verified: $verified
        activated: $activated
        locationId: $locationId
        imageIds: $imageIds
        promoIds : $promoIds
        reviewIds: $reviewIds
  }) {
    _id
        createdAt
        updatedAt
        title
        desc
        industry
        website
        deleted
        verified
        activated
        website
        phone
        facebook
        instagram
        linkedIn
        googlePlace
        googlePlaceId
        latitude
        longitude
        fullAddress
        openingHoursJson
        primaryCategory {
            _id
        }
        secondaryCategory {
            _id
        }
        tertiaryCategory {
            _id
        }
        location {
            _id
        }
        logoImage {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
        }
        backgroundImage {
            _id
            updatedAt
            imageFullUrl
            imageThumbUrl
        }
        promos {
            _id
            updatedAt
            title
            category
            desc
            shortDesc
            conditions
            numberOfReviews
            totalReviewRating
            fullAddress
            fromDate
            toDate
            price
            priceType
            discount
        }
        numberOfReviews
        totalReviewRating
        reviews {
            updatedAt
            _id
        }
  }
}
`;