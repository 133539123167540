const SideMenus = [
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-ungroup text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        miniName: "D",
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Managers",
    icon: "ni ni-shop text-primary",
    state: "nanagerCollapse",
    views: [
      {
        path: "/account",
        name: "Account",
        miniName: "D",
        layout: "/admin",
      }
    ],
  }
];

export default SideMenus;
