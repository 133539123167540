import React, { useState, useEffect, useCallback } from 'react';

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import AccountHeader from "./PageManagerAccountComponents/AccountHeader.js";

// list components
import AccountCard from "./PageManagerAccountComponents/AccountCard.js"
import MerchandCard from "./PageManagerAccountComponents/MerchandCard.js"
import PromotionCard from "./PageManagerAccountComponents/PromotionCard.js"

//Import graphql
import graphqlClient from '../../graphql/index';
import { GET_ACCOUNTS } from "../../graphql/accountQueries";

import { DEBUG } from '../../env'

function PageManageAccount(props) {

  const [firebaseWeb] = useState(props.firebase);
  const [userData, setUserData] = useState(props.userData);
  const [isLoading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("createdAt");
  const [order, setOrder] = useState("desc");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [accountData, setAccountData] = useState(null);

  const [selectedAccountIndex, setSelectedAccountIndex] = useState(Number.MAX_SAFE_INTEGER);
  const [selectedMerchantIndex, setSelectedMerchantIndex] = useState(Number.MAX_SAFE_INTEGER);
  const [selectedPromoIndex, setSelectedPromoIndex] = useState(Number.MAX_SAFE_INTEGER);

  const getAccounts = useCallback(async () => {
    setLoading(true);
    await graphqlClient
      .query({
        query: GET_ACCOUNTS,
        variables: { search: search, sort: sort, order: order, page: currentPage, limit: 5 },
        fetchPolicy: "no-cache"
      })
      .then(result => {
        if (result.errors && result.errors.length > 0) {
          const errMessage = result.errors[0].message;
          if (DEBUG() === true) console.log("err" + errMessage);
          props.redirectHome();
        } else {
          if (DEBUG() === true) console.log("getAccounts by Admin:" + result.data.GetAccounts);
          if (DEBUG() === true) console.log("getAccounts by currentPage:" + result.data.GetAccounts.currentPage);
          if (DEBUG() === true) console.log("getAccounts by totalPages:" + result.data.GetAccounts.totalPages);
          setAccountData(result.data.GetAccounts);
          setTotalCount(result.data.GetAccounts.totalCount);
          setCurrentPage(result.data.GetAccounts.currentPage);
          setTotalPages(result.data.GetAccounts.totalPages);
        }
        setLoading(false);
      })
      .catch(err => {
        if (DEBUG() === true) console.log("err" + err);

        setLoading(false);
      });
  }, [currentPage, order, props, search, sort]);

  // Effect Hook Similar to componentDidMount and componentDidUpdate
  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const handleChangePage = (event, updatedPage) => {
    if (DEBUG() === true) console.log("handleAccountPageUpdate:" + updatedPage);
    setSelectedAccountIndex(Number.MAX_SAFE_INTEGER);
    setCurrentPage(updatedPage);
  };

  const handleChangeSort = (event, updatedSort, updatedOrder) => {
    if (DEBUG() === true) console.log("handleChangeSort:" + updatedSort + " " + updatedOrder);
    setSelectedAccountIndex(Number.MAX_SAFE_INTEGER);
    setCurrentPage(1);
    setSort(updatedSort);
    setOrder(updatedOrder);
  };

  const handleSetSelectedAccountIndex = (index) => {
    setSelectedAccountIndex(index);
    setSelectedMerchantIndex(Number.MAX_SAFE_INTEGER);
    setSelectedPromoIndex(Number.MAX_SAFE_INTEGER);
  }

  const handleSetSelectedMerchantIndex = (index) => {
    setSelectedMerchantIndex(index);
    setSelectedPromoIndex(Number.MAX_SAFE_INTEGER);
  }

  return (
    <>
      <LoadingOverlay
        active={isLoading === true} spinner={<BounceLoader />} >
        <AccountHeader
          name="Account"
          parentName="Managers"
          getAccounts={getAccounts} 
          setSelectedAccountIndex={setSelectedAccountIndex} 
          setSelectedMerchantIndex={setSelectedMerchantIndex} 
          />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <AccountCard
                accountData={accountData}
                currentPage={currentPage}
                totalPages={totalPages}
                sort={sort}
                order={order}
                handleChangePage={(event, updatedPage) =>
                  handleChangePage(event, updatedPage)
                }
                handleChangeSort={(event, updatedSort, updatedOrder) =>
                  handleChangeSort(event, updatedSort, updatedOrder)
                }
                selectedAccountIndex={selectedAccountIndex}
                setSelectedAccountIndex={handleSetSelectedAccountIndex}
              />
            </div>
          </Row>


          {(selectedAccountIndex !== Number.MAX_SAFE_INTEGER && accountData !== null) && (
            <MerchandCard
              selectedAccountMerchants={accountData.accounts[selectedAccountIndex].company.merchants}
              selectedMerchantIndex={selectedMerchantIndex}
              setSelectedMerchantIndex={handleSetSelectedMerchantIndex}
              getAccounts={getAccounts}
              companyId={accountData.accounts[selectedAccountIndex].company._id}
            />
          )}

          {(selectedAccountIndex !== Number.MAX_SAFE_INTEGER && selectedMerchantIndex !== Number.MAX_SAFE_INTEGER && accountData !== null) && (
            <PromotionCard
              selectedAccountMerchantPromos={accountData.accounts[selectedAccountIndex].company.merchants[selectedMerchantIndex].promos}
              selectedPromoIndex={selectedPromoIndex}
              setSelectedPromoIndex={setSelectedPromoIndex}
              getAccounts={getAccounts}
              merchantId={accountData.accounts[selectedAccountIndex].company.merchants[selectedMerchantIndex]._id}
            />
          )}
        </Container>
      </LoadingOverlay>
    </>
  );
}

export default PageManageAccount;
