// reactstrap components
import {
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";
const numberOfPaginationItemToDisplay = 4;

function ListPagination(props) {

    const totalPage = props.totalPage;
    let currentPage = props.currentPage;
    let paginationItems = [];
    let pageCount = totalPage;
    let startPage = 1;

    console.log("ListPagination:" + currentPage);

    if (currentPage > 1) {
        paginationItems.push(
            <PaginationItem key={0}>
                <PaginationLink onClick={e => props.handleChangePage(e, currentPage - 1)}>
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                </PaginationLink>
            </PaginationItem>
        );
    }

    if (currentPage >= numberOfPaginationItemToDisplay && totalPage === 1) {
        //Always add page 1
        paginationItems.push(
            <PaginationItem key={1} active={currentPage === 1 ? true : false}>
                <PaginationLink onClick={e => props.handleChangePage(e, 1)}>
                    {1}
                </PaginationLink>
            </PaginationItem>
        );
        pageCount = currentPage + numberOfPaginationItemToDisplay;
        if (pageCount > totalPage) {
            pageCount = totalPage;
        }
        if (pageCount > numberOfPaginationItemToDisplay) {
            startPage = pageCount - numberOfPaginationItemToDisplay;
        }
    } else {
        for (let i = startPage; i <= pageCount; i++) {
            paginationItems.push(
                <PaginationItem key={i} active={currentPage === i ? true : false}>
                    <PaginationLink onClick={e => props.handleChangePage(e, i)}>
                        {i}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        if (currentPage < pageCount) {
            paginationItems.push(
                <PaginationItem key={pageCount + 1}>
                    <PaginationLink onClick={e => props.handleChangePage(e, currentPage + 1)} >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
            );
        }
    }

    return (
        <Pagination
            className="pagination justify-content-end mb-0"
            listClassName="justify-content-end mb-0"
        >
            {paginationItems}
        </Pagination>
    );
}

export default ListPagination;