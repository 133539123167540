import { gql } from '@apollo/client';

export const CREATE_COMPANY = gql`
mutation CreateCompany($accountId: String, $name: String!, $companyNumber: String, $nzbn: String, $companyStatus: String, $incorporationDate: String, $fullAddress: String, $tradingName: String, $phone: String) {
  CreateCompany(createCompanyInput:{
    accountId: $accountId
    name: $name
    companyNumber: $companyNumber
    nzbn: $nzbn
    companyStatus: $companyStatus
    incorporationDate: $incorporationDate
    fullAddress: $fullAddress
    tradingName: $tradingName
    phone: $phone
  }) {
    _id
    createdAt
    updatedAt
    incorporationDate
    phone
    companyStatus
    merchants {
      _id
      title
    }
  }
}
`;

export const UPDATE_COMPANY = gql`
mutation UpdateCompany($companyId: String!, $name: String, $companyNumber: String, $nzbn: String, $companyStatus: String, $incorporationDate: String, $fullAddress: String, $tradingName: String, $phone: String) {
  UpdateCompany(updateCompanyInput:{
    companyId: $companyId
    name: $name
    companyNumber: $companyNumber
    nzbn: $nzbn
    companyStatus: $companyStatus
    incorporationDate: $incorporationDate
    fullAddress: $fullAddress
    tradingName: $tradingName
    phone: $phone
  }) {
    _id
    createdAt
    updatedAt
    incorporationDate
    phone
    companyStatus
    merchants {
      _id
      title
    }
  }
}
`;

export const CREATE_COMPANY_BY_ADMIN = gql`
mutation CreateCompanyByAdmin($userEmail: String!, $name: String!, $phone: String) {
  CreateCompanyByAdmin(createCompanyByAdminInput:{
    email: $userEmail
    name: $name
    phone: $phone
  }) {
    _id
    createdAt
    updatedAt
    name
    phone
    merchants {
      _id
      title
    }
  }
}
`;
